<template>
  <div id="main-push-con">
    <img
      v-show="isPushDetail"
      class="close-ico"
      src="~assets/image/ico/close.svg"
      alt="닫기 아이콘"
      name="close"
      @click="PushComeback"
    />
    <div class="mainTitle-con">
      <div id="push-con">
        <div class="Title-con" v-if="!isPushDetail">
          <h2 class="mainTitle">근무매칭</h2>
          <p class="subTitle">근무매칭 현황을 실시간으로 확인가능합니다.</p>
        </div>
        <div id="push-content">
          <swiper class="push-swiper" :options="pushOption">
            <swiper-slide
              class="push-swiper-wrapper"
              v-for="swiperList in swiperList"
              :key="swiperList.Guide"
            >
              <img
                class="pushImg"
                :src="swiperList.imgUrl"
                alt="이력서관리 설명서"
                name="pushImg"
                @click="PushDetail"
            /></swiper-slide>
            <div
              v-show="isPushDetail"
              class="swiper-button-prev"
              slot="button-prev"
            ></div>
            <div
              v-show="isPushDetail"
              class="swiper-button-next"
              slot="button-next"
            ></div
          ></swiper>
        </div>
        <div
          class="swiper-pagination pop-swiper-pagination"
          slot="pagination"
          v-show="isPushDetail"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'swiper-example-loop',
  title: 'Loop mode / Infinite loop',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      pushOption: {
        // slidesPerView: 'auto',
        slidesPerView: 'auto',
        spaceBetween: 30,
        slideToClickedSlide: false,
        centeredSlides: false,
        // autoplay: {
        //   delay: 1500,
        //   disableOnInteraction: false,
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          // 버튼 사용자 지정
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      isPushDetail: false,
      windowWidth: window.innerWidth,
      touchData: [
        {
          startId: '',
          startY: '',
          moveY: '',
          endY: '',
        },
      ],
      swiperList: [
        {
          imgUrl: require('../../../assets/image/main/work-matching-1.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-2.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-3.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-4.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-5.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-6.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-7.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-8.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-9.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/work-matching-10.png'),
        },
      ],
    }
  },
  // created() {
  //   if (this.isResumeDetail === true && this.windowWidth < 1023) {
  //     this.resumeOption.slidesPerView = 1
  //   } else {
  //     this.resumeOption.slidesPerView = 'auto'
  //   }
  // },
  watch: {
    isPushDetail() {
      if (this.isPushDetail === true && this.windowWidth < 1023) {
        this.pushOption.slidesPerView = 1
      } else {
        this.pushOption.slidesPerView = 'auto'
      }
      // 확대
      if (this.isPushDetail === true) {
        window
          .$(
            '#main-push-con, #main-push-con > .mainTitle-con, #push-con, #push-content, .push-swiper > .swiper-wrapper, .pushImg, .swiper-pagination,.push-swiper-wrapper',
          )
          .addClass('on')
        window.$('.push-swiper > .swiper-wrapper').addClass('pushWrapper')
        window.$('.container, .__guide, .sub-cont').css('position', 'inherit')
        window.$('.container').css('overflow-y', 'inherit')
      }
      // 정상
      if (this.isPushDetail === false) {
        window
          .$(
            '#main-push-con, #main-push-con > .mainTitle-con, #push-con, #push-content, .push-swiper > .swiper-wrapper, .pushImg, .swiper-pagination,.push-swiper-wrapper',
          )
          .removeClass('on')
        window.$('.container, .__guide, .sub-cont').css('position', 'relative')
        window.$('.container').css('overflow-y', 'auto')
      }
      // 모바일 확대
      if (this.isPushDetail === true && this.windowWidth < 1023) {
        window.$('.main').css('position', 'inherit')
        window.$('.close-ico').addClass('on')
      }
      // 모바일 정상
      if (this.isPushDetail === false && this.windowWidth < 1023) {
        window.$('.main').css('position', 'absolute')
        window.$('.close-ico').removeClass('on')
      }
    },
  },
  methods: {
    PushDetail() {
      this.$nextTick(() => {
        this.isPushDetail = true
      })
    },
    PushComeback() {
      this.$nextTick(() => {
        this.isPushDetail = false
      })
    },
  },
}
</script>

<style lang="scss">
#main-push-con.on {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.mainTitle-con.on {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
}
#push-con.on {
  height: 100%;
}
#push-content.on {
  height: 80vh !important;
}
.swiper-wrapper.on {
}
.push-swiper img.on {
  height: 80%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
main.sub.__contact
  #main-push-con
  .mainTitle-con
  #push-con
  #push-content
  .push-swiper
  .swiper-wrapper
  .push-swiper-wrapper.on {
  margin-right: 0;
  margin-left: 0;
  width: 100% !important;
}
.swiper-pagination.on {
  bottom: 0;
}
@media (max-width: 1024px) {
  #main-push-con.on {
    padding: 5px !important;
    background: #333;
  }
  main.sub.__contact.__guide #main-push-con .close-ico.on {
    right: 20px;
    top: 20px;
    width: 30px;
  }
  .mainTitle-con.on {
    height: 90%;
  }
  #push-content {
    height: 85vh;
  }
  main.sub.__contact.__guide
    #main-push-con
    .mainTitle-con
    #push-con
    #push-content
    .push-swiper
    .swiper-wrapper
    .push-swiper-wrapper.on {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .push-swiper img.on {
    // height: auto !important;
    // width: 100% !important;
  }
  .swiper-pagination.on {
    position: absolute;
    left: 50%;
    bottom: 0 !important;
    transform: translateX(-50%);
    width: auto;
    display: flex;
    justify-content: space-around;
  }
}
</style>
