<template>
  <div id="main-resume-con">
    <img
      v-show="isResumeDetail"
      class="close-ico"
      src="~assets/image/ico/close.svg"
      alt="닫기 아이콘"
      name="close"
      @click="resumeComeback"
    />
    <div class="mainTitle-con">
      <div id="resume-con">
        <div class="Title-con" v-if="!isResumeDetail">
          <h2 class="mainTitle">이력서 관리</h2>
          <p class="subTitle">회원님의 이력서를 관리할 수 있습니다.</p>
        </div>
        <div id="resume-content">
          <swiper
            class="resume-swiper"
            :options="resumeOption"
            ref="resumeSwiper"
          >
            <swiper-slide
              class="resume-swiper-wrapper"
              v-for="(swiperList, index) in swiperList"
              :key="swiperList.Guide"
              :index="index"
            >
              <img
                class="resumeImg"
                :src="swiperList.imgUrl"
                name="resumeImg"
                alt="이력서관리 설명서"
                @click="resumeDetail"
                :index="index"
            /></swiper-slide>
            <div
              v-show="isResumeDetail"
              class="swiper-button-prev"
              slot="button-prev"
            ></div>
            <div
              v-show="isResumeDetail"
              class="swiper-button-next"
              slot="button-next"
            ></div
          ></swiper>
        </div>
        <div
          class="swiper-pagination pop-swiper-pagination"
          slot="pagination"
          v-show="isResumeDetail"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'swiper-example-loop',
  title: 'Loop mode / Infinite loop',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      resumeOption: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        slideToClickedSlide: true,
        centeredSlides: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          // 버튼 사용자 지정
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      isResumeDetail: false,
      windowWidth: window.innerWidth,
      touchData: [
        {
          startId: '',
          startY: '',
          moveY: '',
          endY: '',
        },
      ],
      swiperList: [
        {
          imgUrl: require('../../../assets/image/main/Resume-guide-1.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/Resume-guide-2.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/Resume-guide-3.png'),
        },
        {
          imgUrl: require('../../../assets/image/main/Resume-guide-4.png'),
        },
      ],
    }
  },
  // created() {
  //   if (this.isResumeDetail === true && this.windowWidth < 1023) {
  //     this.resumeOption.slidesPerView = 1
  //   } else {
  //     this.resumeOption.slidesPerView = 'auto'
  //   }
  // },
  watch: {
    isResumeDetail() {
      if (this.isResumeDetail === true && this.windowWidth < 1023) {
        this.resumeOption.slidesPerView = 1
      } else {
        this.resumeOption.slidesPerView = 'auto'
      }
      // 확대
      if (this.isResumeDetail === true) {
        window
          .$(
            '#main-resume-con, #main-resume-con > .mainTitle-con, #resume-con,#resume-content, .resume-swiper > .swiper-wrapper, .resume-swiper-wrapper > img, .swiper-pagination,.resume-swiper-wrapper',
          )
          .addClass('on')
        window.$('.resume-swiper > .swiper-wrapper').addClass('resumeWrapper')
        window.$('.container, .__guide, .sub-cont').css('position', 'inherit')
        window.$('.container').css('overflow-y', 'inherit')
        // var lelWidth = window.$('.swiper-wrapper').width()
        // window.$('.swiper-wrapper').attr('style', 'left: - lelWidth')
      }
      // 정상
      if (this.isResumeDetail === false) {
        window
          .$(
            '#main-resume-con, #main-resume-con > .mainTitle-con, #resume-con,#resume-content, .resume-swiper > .swiper-wrapper, .resume-swiper-wrapper > img, .swiper-pagination,.resume-swiper-wrapper',
          )
          .removeClass('on')
        window.$('.container, .__guide, .sub-cont').css('position', 'relative')
        window.$('.container').css('overflow-y', 'auto')
      }
      // 모바일 확대
      if (this.isResumeDetail === true && this.windowWidth < 1023) {
        window.$('.main').css('position', 'inherit')
        window.$('.close-ico').addClass('on')
      }
      // 모바일 정상
      if (this.isResumeDetail === false && this.windowWidth < 1023) {
        window.$('.main').css('position', 'absolute')
        window.$('.close-ico').removeClass('on')
      }
    },
  },
  // mounted() {
  //   this.swiper.slideTo(3, 1000, false)
  // },
  methods: {
    resumeDetail() {
      this.$nextTick(() => {
        this.isResumeDetail = true
        // let index = e.target.getAttribute('index')
        // console.log(index)
        // window.$('.swiper-wrapper').attr('style', 'left : -1000px')
      })
    },
    resumeComeback() {
      this.$nextTick(() => {
        this.isResumeDetail = false
      })
    },
  },
}
</script>

<style lang="scss">
#main-resume-con.on {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.mainTitle-con.on {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
}
#resume-con.on {
  height: 100%;
}
#resume-content.on {
  height: 80vh !important;
}
.swiper-wrapper.on {
}
.resume-swiper-wrapper img.on {
  height: 80%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
main.sub.__contact
  #main-resume-con
  .mainTitle-con
  #resume-con
  #resume-content
  .resume-swiper
  .swiper-wrapper
  .resume-swiper-wrapper.on {
  margin-right: 0;
  margin-left: 0;
  width: 100% !important;
}
.swiper-pagination.on {
  bottom: 0;
}
@media (max-width: 1024px) {
  #main-resume-con.on {
    padding: 5px !important;
    background: #333;
  }
  main.sub.__contact.__guide #main-resume-con .close-ico.on {
    right: 20px;
    top: 20px;
    width: 30px;
  }
  .mainTitle-con.on {
    height: 90%;
  }
  #resume-content {
    height: 85vh;
  }
  main.sub.__contact.__guide
    #main-resume-con
    .mainTitle-con
    #resume-con
    #resume-content
    .resume-swiper
    .swiper-wrapper
    .resume-swiper-wrapper.on {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .resume-swiper-wrapper img.on {
    // height: auto !important;
    // width: 100% !important;
  }
  .swiper-pagination.on {
    position: absolute;
    left: 50%;
    bottom: 0 !important;
    transform: translateX(-50%);
    width: auto;
    display: flex;
    justify-content: space-around;
  }
}
</style>
