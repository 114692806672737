<template>
  <div>
    <main class="sub __contact __guide">
      <div class="inner __faq">
        <div class="tit req-type">
          <h1>앱 사용 설명서</h1>
          <p class="stit">앱 사용 설명서</p>
        </div>
        <div class="sub-cont">
          <resume-guide></resume-guide>
          <smart-match-guide></smart-match-guide>
          <push-guide></push-guide>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import ResumeGuide from 'Components/resume/content/ResumeGuide'
import SmartMatchGuide from 'Components/myPage/partials/SmartMatchGuide'
import pushGuide from 'Components/find/partials/pushGuide'
export default {
  components: { ResumeGuide, SmartMatchGuide, pushGuide },
  data() {
    return {}
  },
  created() {
    this.$nextTick(() => {
      window.$(document).on('click', '[name=resumeImg]', function () {
        // let mobileWidth = window.innerWidth
        let resumeindex = window.$('[name=resumeImg]').index(this)
        let resumeWidth = window.$('.resumeWrapper').width() + 30
        // console.log(-resumeindex * resumeWidth)
        window
          .$('.resumeWrapper')
          .attr(
            'style',
            'transform: translate3d(' + -resumeindex * resumeWidth + 'px,0,0)',
          )
      })
      window.$(document).on('click', '[name=smartMatImg]', function () {
        // let mobileWidth = window.innerWidth
        let smartMatindex = window.$('[name=smartMatImg]').index(this)
        let smartMatWidth = window.$('.smartMatWrapper').width() + 30
        console.log(-smartMatindex * smartMatWidth)
        // console.log(resumeindex)
        console.log('pc' + smartMatWidth)
        // console.log(-resumeindex * resumeWidth)
        window
          .$('.smartMatWrapper')
          .attr(
            'style',
            'transform: translate3d(' +
              -smartMatindex * smartMatWidth +
              'px,0,0)',
          )
      })
      window.$(document).on('click', '[name=pushImg]', function () {
        // let mobileWidth = window.innerWidth
        let pushindex = window.$('[name=pushImg]').index(this)
        let pushWidth = window.$('.pushWrapper').width() + 30
        console.log(-pushindex * pushWidth)
        // console.log(resumeindex)
        // console.log(-resumeindex * resumeWidth)
        window
          .$('.pushWrapper')
          .attr(
            'style',
            'transform: translate3d(' + -pushindex * pushWidth + 'px,0,0)',
          )
      })
    })
  },
}
window.onload = function () {}
</script>

<style lang="scss"></style>
